/* eslint-disable */
import React, { Component } from 'react';
import logo from '../logo.png'
import physical from '../barcodeimages/physical.png'
import degauss from '../barcodeimages/degauss.png'
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';

// import {moment as moment1} from "moment"

import { CSVLink } from 'react-csv';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import BarcodeScanner from "simple-barcode-scanner";
import Moment from 'react-moment';
import moment from 'moment-timezone';
import api from '../api';

// import swal from 'sweetalert';
import swal from '@sweetalert/with-react'

<script src="https://unpkg.com/sweetalert/dist/sweetalert.min.js"></script>

var $ = require('jquery');


var subProps;
var self;
// var barCodeData;
const scanner = BarcodeScanner();
const phyOptions = [
    {
        text: "Yes",
        class: "onSelect"
    },
    {
        text: "No",
        class: "offSelect"
    }
]

const ssdHddOptions = [
    {
        text: "HDD",
        class: "onSelect"
    },
    {
        text: "SSD",
        class: "offSelect"
    }
]

scanner.on(data => {
    if (data != "YES" && data != "NEGATIVE" && data != "SUCCESSFUL" && data != "FAILED") {
        // document.getElementById("SerialNumber").value = data;
        self.setState({
            SerialNumber: data
        });
    }
    if (data == "YES" || data == "NEGATIVE") {
        self.setState({
            Physical_Destruction: data,
        });
        // document.getElementById("Physical_Destruction").value = data; 
    }
    if (data == "SUCCESSFUL" || data == "FAILED") {
        self.setState({
            Degauss_Success: data,
        });
        // document.getElementById("Degauss_Success").value = data; 
    }
    self.addNewRow();
});


// const { register, handleSubmit, watch, errors } = useForm();

// const csvHeaders =['Date Time','Device','User','Serial Number','Physical Destruction?','Wireless Initials','SSD OR HDD','Comments']
const csvHeaders = ['Date Time', 'Device', 'User', 'Serial Number', 'Wireless Initials', 'Degauss Success?', 'Physical Destruction?', 'Comments']

class Home extends Component {

    constructor(props) {
        super(props);
        subProps = props;
        this.state = {
            UserDeviceId: null,
            Date_Time: new Date(),
            Device: 'T-1',
            DeviceOther: 'T-1',
            Physical_Destruction_Device: 'NONE',
            Phy_Other: '',
            saveData: false,
            Description: '',
            modal: false,
            searchText: '',
            User: '',
            UserForm: '',
            ActiveUser: '',
            ActiveuserFlag: false,
            logo: logo,
            SerialNumber: '',
            Physical_Destruction: '',
            Degauss_Success: '',
            ModifiedDate: new Date(),
            SSD_HDD: 'SSD',
            CreatedBy: localStorage.getItem("usersession"),
            Comments: '',
            Wireless_Initials: '',
            userDevices: [],
            csvDatas: [],
            LastModifiedDate: "",
            hiddenExtraRows: 'hidden',
            min: 1,
            max: 50
        };
        self = this;
    }



    logout() {
        localStorage.clear();
        subProps.parentContext.props.history.push("/");
    }

    onMultiSelect(text, fieldName) {
        const state = self.state;
        state[fieldName] = text;
        self.setState(state);
    }

    componentDidMount() {

        this.fetchAllData();
        api.get("customerlogo/" + localStorage.getItem("usersession")).then(res => {
            if (res.data != null)
                self.setState({ logo: process.env.REACT_APP_API_URL + res.data.Logo });
        });
    }

    fetchAllData() {
        self.setState({
            searchText: ''
        });

        // alert("DKKDKDKDKD");

        api.get('userdevices/' + self.state.CreatedBy)
            .then(res => {
                var csvDatas = [];
                res.data.map(userDevice => {
                    csvDatas.push({
                        'Date Time': userDevice.Date_Time,
                        'Device': userDevice.Device,
                        'User': userDevice.User,
                        'Physical_Destruction_Device': userDevice.Physical_Destruction_Device,
                        'Serial Number': userDevice.SerialNumber,
                        'Wireless Initials': userDevice.Wireless_Initials,
                        'Physical Destruction?': userDevice.Physical_Destruction,
                        'Degauss Success?': userDevice.Degauss_Success,
                        'Comments': userDevice.Comments
                    });
                });
                if (res.data.length > 0) {
                    if (res.data[0].ModifiedDate != null && res.data[0].ModifiedDate != undefined && res.data[0].ModifiedDate != "") {
                        var newDate = moment(res.data[0].ModifiedDate).format('hh:mm:ss A').toString() + ' EST | '
                            + moment(res.data[0].ModifiedDate).format('MM/DD/YYYY').toString();
                        self.setState({ LastModifiedDate: newDate })
                    }
                    else {
                        self.setState({ LastModifiedDate: "" })
                    }
                }
                self.setState({ userDevices: res.data, csvDatas: csvDatas });
            });
    }

    addNewRow() {
        self.setState({
            hiddenExtraRows: ''
        });
    }

    onChange = (e) => {
        const state = self.state;
        state[e.target.name] = e.target.value;
        self.setState(state);
    }

    onSelectChange = (e) => {
        if (e.target.value == "Other") {
            $("#DeviceOther").show();
            $("#Device").hide();
            const state = self.state;
            state["Device"] = "";
            state["DeviceOther"] = e.target.value;
            self.setState(state);
        }
        else {
            $("#Device").show();
            $("#DeviceOther").hide();
            const state = self.state;
            state["Device"] = "";
            state["DeviceOther"] = e.target.value;
            self.setState(state);
        }
    }

    onSelectChange1 = (e) => {
        console.log(e.target.value);
        if (e.target.value == "OTHER") {
            const state = self.state;
            state["Physical_Destruction_Device"] = e.target.value;
            state["Phy_Other"] = e.target.value;

            self.setState(state);
        }
        else {
            const state = self.state;
            state["Physical_Destruction_Device"] = e.target.value;
            state["Phy_Other"] = e.target.value;
            self.setState(state);

        }
    }

    handleChange(date) {
        self.setState({
            Date_Time: date
        });
    }

    activeUser() {


        if (self.state.UserForm != "") {

            if (!/\S+@\S+\.\S+/.test(self.state.UserForm)) {
                // alert("Email is  invalid");
                swal(
                    <div>
                        {/* <h1>Validation Error..!!</h1> */}
                        <p>
                            User email address is invalid...!!
                        </p>
                    </div>
                );
                return false;
            }


            api.post('activeusers', { username: self.state.UserForm, activateStatus: true })
                .then((result) => {
                    self.setState({
                        ActiveUser: result.data.username,
                        User: result.data.username
                    });
                });
            self.setState({ ActiveuserFlag: true });
        }
        else {
            swal(
                <div>
                    {/* <h1>Validation Error..!!</h1> */}
                    <p>
                        Enter user email address...!!
                    </p>
                </div>
            );
            // alert("Enter user email address");
        }
    }

    isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    saveDeviceData(event) {

        event.preventDefault();



        if (self.state.User == "" || self.state.SerialNumber == "" || self.state.Wireless_Initials == "" || self.state.Degauss_Success == "" || self.state.Physical_Destruction == "") {

            swal(
                <div>
                    <h1>Validation Error..!!</h1>
                    {self.state.User == "" ? (
                        <p>
                            User field  is required.
                        </p>
                    ) : null}

                    {self.state.SerialNumber == "" ? (
                        <p>
                            Serial number field  is required.
                        </p>
                    ) : null}


                    {self.state.Wireless_Initials == "" ? (
                        <p>
                            Witness initials field  is required.
                        </p>
                    ) : null}

                    {self.state.Degauss_Success == "" ? (
                        <p>
                            Degauss success field  is required.
                        </p>
                    ) : null}


                    {self.state.Physical_Destruction == "" ? (
                        <p>
                            Physical destruction field  is required.
                        </p>
                    ) : null}

                </div>
            );
            return false;

        }
        else {

            const state = self.state;

            if (!/\S+@\S+\.\S+/.test(self.state.User)) {

                swal(
                    <div>
                        {/* <h1>Validation Error..!!</h1> */}
                        <p>
                            User email address is invalid....!!
                        </p>
                    </div>
                );
                return false;
            }




            if (self.state.User.length >= 51) {

                swal(
                    <div>
                        {/* <h1>Validation Error..!!</h1> */}
                        <p>
                            User value must be less than 50 characters..!!
                        </p>
                    </div>
                );
                // alert("User value must be less than 50 characters") 
                return false;
            }

            if (self.state.SerialNumber >= 51) {

                swal(
                    <div>
                        {/* <h1>Validation Error..!!</h1> */}
                        <p>
                            Serial number value must be less than 50 characters..!!
                        </p>
                    </div>
                );

                // alert("Serial number value must be less than 50 characters") 
                return false;
            }
            if (self.state.Wireless_Initials >= 51) {

                swal(
                    <div>
                        {/* <h1>Validation Error..!!</h1> */}
                        <p>
                            Witness initials value must be less than 50 characters..!!
                        </p>
                    </div>
                );
                // alert("Witness initials value must be less than 50 characters") 
                return false;
            }

            if (self.state.Degauss_Success >= 51) {
                swal(
                    <div>
                        {/* <h1>Validation Error..!!</h1> */}
                        <p>
                            Degauss success value must be less than 50 characters..!!
                        </p>
                    </div>
                );
                // alert("Degauss success value must be less than 50 characters") 
                return false;
            }

            if (self.state.Physical_Destruction >= 51) {
                swal(
                    <div>
                        {/* <h1>Validation Error..!!</h1> */}
                        <p>
                            Physical destruction value must be less than 50 characters..!!
                        </p>
                    </div>
                );
                // alert("Physical destruction value must be less than 50 characters") 
                return false;
            }




            if (state["DeviceOther"] != "Other") {
                state["Device"] = state["DeviceOther"];
                self.setState(state)
            }
            if (state["Physical_Destruction_Device"] === "OTHER") {
                state["Phy_Other"] = state["Physical_Destruction_Device"]
                state["Physical_Destruction_Device"] = state["Description"];
                self.setState(state);
            }
            api.post('userdevices', self.state)
                .then((result) => {
                    console.log(state["ActiveuserFlag"]);
                    console.log(state["Phy_Other"]);
                    console.log(state["Description"]);
                    if (state["ActiveuserFlag"] == true && state["Phy_Other"] == "OTHER") {
                        self.setState({
                            // User: '',
                            // UserForm: '',
                            // ActiveUser: '',
                            // ActiveuserFlag: false,
                            UserDeviceId: null,
                            Date_Time: new Date(),
                            ModifiedDate: new Date(),
                            Device: 'T-1',
                            DeviceOther: 'T-1',
                            saveData: true,
                            modal: false,
                            searchText: '',
                            SerialNumber: '',
                            Physical_Destruction: 'NONE',
                            Degauss_Success: '',
                            SSD_HDD: 'SSD',
                            CreatedBy: localStorage.getItem("usersession"),
                            Comments: '',
                            Wireless_Initials: '',
                            hiddenExtraRows: 'hidden',
                            Physical_Destruction_Device: 'NONE',
                            Phy_Other: 'NONE'

                        });
                    } else {
                        self.setState({
                            // User: '',
                            // UserForm: '',
                            // ActiveUser: '',
                            // ActiveuserFlag: false,
                            UserDeviceId: null,
                            Date_Time: new Date(),
                            ModifiedDate: new Date(),
                            Device: 'T-1',
                            DeviceOther: 'T-1',
                            saveData: true,
                            Physical_Destruction_Device: 'NONE',
                            Description: '',
                            modal: false,
                            searchText: '',
                            SerialNumber: '',
                            Physical_Destruction: 'NONE',
                            Degauss_Success: '',
                            SSD_HDD: 'SSD',
                            CreatedBy: localStorage.getItem("usersession"),
                            Comments: '',
                            Wireless_Initials: '',
                            hiddenExtraRows: 'hidden',
                            Phy_Other: 'NONE'
                        });
                    }

                    self.fetchAllData();
                });

        }
    }

    hideForm() {
        self.setState({
            UserDeviceId: null,
            Date_Time: new Date(),
            ModifiedDate: new Date(),
            Device: 'T-1',
            DeviceOther: 'T-1',
            Physical_Destruction_Device: 'NONE',
            Description: '',
            modal: false,
            searchText: '',
            // User: '',
            // UserForm: '',
            // ActiveUser: '',
            SerialNumber: '',
            Physical_Destruction: '',
            Degauss_Success: '',
            SSD_HDD: 'SSD',
            CreatedBy: localStorage.getItem("usersession"),
            Comments: '',
            Wireless_Initials: '',
            // userDevices:[],
            // csvDatas:[],
            hiddenExtraRows: 'hidden'
        });
    }

    printTableData() {
        api.get("customerlogo/" + localStorage.getItem("usersession")).then(res => {
            var logo;
            if (res.data != null) {
                logo = process.env.REACT_APP_API_URL + res.data.Logo;
            }
            else {
                logo = window.location.protocol + "//" + window.location.host + self.state.logo;
            }
            var htmlString = "";
            htmlString += "<title>Proton Data Security</title>";
            htmlString += "<style>";
            htmlString += "table.report-container {";
            htmlString += "page-break-after:always;";
            htmlString += "}";
            htmlString += "thead.report-header {";;
            htmlString += "display:table-header-group;";
            htmlString += "}";
            htmlString += "tfoot.report-footer {";
            htmlString += "display:table-footer-group;";
            htmlString += "} ";;
            htmlString += "</style>";
            htmlString += "<table class='report-container'>";
            htmlString += "<thead class='report-header'>";
            htmlString += " <tr>";
            htmlString += "<th class='report-header-cell'>";
            htmlString += "<div class='header-info' style='text-align:center'>";
            htmlString += "<img src='" + logo + "' style='margin-bottom:1em'>"
            htmlString += " </div>";
            htmlString += "</th>";
            htmlString += "</tr>";
            htmlString += "</thead>";
            htmlString += "<tfoot class='report-footer'>";
            htmlString += "<tr>";
            htmlString += "<td class='report-footer-cell'>";
            htmlString += "<div class='footer-info' style='margin-top:1em;text-align:center'>";
            htmlString += "<h4>Powered by Proton Data Security: https://www.protondata.com</h4>"
            htmlString += "</div>";
            htmlString += "</td>";
            htmlString += " </tr>";
            htmlString += "</tfoot>";
            htmlString += "<tbody class='report-content'>";
            htmlString += "<tr>";
            htmlString += "<td class='report-content-cell'>";
            htmlString += "<table class='table table-bordered'> <thead>" +
                " <tr>" +
                "<th>DATE AND TIME</th>" +
                "<th>DEVICE</th>" +
                "<th>PHYSICAL DESTRUCTION DEVICE</th>" +
                "<th>USER</th>" +
                "    <th>SERIAL NUMBER</th>" +
                "   <th>WITNESS INITIALS</th>" +
                "   <th>DEGAUSS SUCCESS?</th>" +
                "   <th>PHYSICAL DESTRUCTION?</th>" +
                "   <th>COMMENTS</th>" +
                "</tr>" +
                "</thead>" +
                "<tbody>" +
                self.GetPrintTableBody() +
                "</tbody>" +
                "<table>";
            htmlString += "</td>";
            htmlString += "</tr>";
            htmlString += "</tbody>";
            htmlString += "<table>";

            var pri = document.getElementById("ifmcontentstoprint").contentWindow;
            pri.document.open();
            pri.document.write(htmlString);
            setTimeout(function () { // wait until all resources loaded 
                pri.document.close();
                pri.focus();
                pri.print();
            }, 500);
        });
    }

    GetPrintTableBody() {
        var htmlString = "";
        self.state.userDevices.forEach(element => {
            htmlString += " <tr>" +
                "<td>" + element.Date_Time + "</td>" +
                "<td  align='center'>" + element.Device + "</td>" +
                "<td  align='center'>" + element.Physical_Destruction_Device + "</td>" +
                "<td  align='center'>" + element.User + "</td>" +
                "<td  align='center'>" + element.SerialNumber + "</td>" +
                "<td  align='center'>" + element.Wireless_Initials + "</td>" +
                "<td align='center'>" + element.Degauss_Success + "</td>" +
                "<td align='center'>" + element.Physical_Destruction + "</td>" +
                "<td  align='center'>" + element.Comments + "</td>" +
                "</tr>";
        });
        return htmlString;
    }

    toggle() {
        self.setState({
            modal: !self.state.modal
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        if (self.state.searchText === "") {
            alert("Search text is required.");
        }
        else {
            api.get('device-search/' + self.state.searchText + "/" + localStorage.getItem("usersession")).then(res => {
                var csvDatas = [];
                res.data.map(userDevice => {
                    csvDatas.push({
                        'Date Time': userDevice.Date_Time,
                        'Device': userDevice.Device,
                        'User': userDevice.User,
                        'Physical_Destruction_Device': userDevice.Physical_Destruction_Device,
                        'Description': userDevice.Description,
                        'Serial Number': userDevice.SerialNumber,
                        'Wireless Initials': userDevice.Wireless_Initials,
                        'Physical Destruction?': userDevice.Physical_Destruction,
                        'Degauss Success?': userDevice.Degauss_Success,
                        'Comments': userDevice.Comments
                    });
                });

                self.setState({ userDevices: res.data, csvDatas: csvDatas, modal: !self.state.modal });
            });
        }
    }

    selectImage() {
        $("#filelogo").click();
    }

    uploadImage = event => {
        const data = new FormData();
        data.append('Logo', event.target.files[0]);
        api.post("customerlogo/" + localStorage.getItem("usersession"), data).then(res => {
            alert(res.data.message);
            self.setState({ logo: process.env.REACT_APP_API_URL + res.data.Logo });
        });
    }

    render() {
        return (
            <div className="row container">
                <iframe id="ifmcontentstoprint" style={{ height: 0, width: 0, position: "absolute", display: "none" }}></iframe>
                <Modal backdrop="static" isOpen={this.state.modal}>
                    <form onSubmit={this.handleSubmit}>
                        <ModalHeader>Search Device</ModalHeader>
                        <ModalBody>
                            <div className="form-group">
                                <input type="text" placeholder="Enter search text" name="searchText" value={this.state.searchText} onChange={this.onChange} className="form-control" />
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <input type="submit" value="Submit" color="primary" className="btn btn-success" />
                            <Button color="danger" onClick={this.toggle}>Close</Button>
                        </ModalFooter>
                    </form>
                </Modal>
                {/* <div className="col-md-12 float-left">
                    <div className="col-md-8 float-left">
                        <h2 className="adminPageTitle">ADMINISTRATION PANEL</h2>
                    </div>
                    {/* <div className="col-md-4 float-left" style={{textAlign:'right'}}>
                                         
                        <h2 className="adminPageTitle"> <Link  style={{color:'white'}} to={`/admin-panel`}>ADMIN HOME</Link></h2> 
                    </div> 
                </div> */}
                <form onSubmit={this.saveDeviceData}>
                    <div className="col-md-12 float-left" style={{ marginTop: '2em' }}>
                        <div className="col-md-9  float-left table-responsive">
                            <table className="homeTable table">
                                <thead>
                                    <tr>
                                        <th>DATE AND TIME</th>
                                        <th>DEVICE</th>
                                        <th>PHYSICAL DESTRUCTION DEVICE</th>
                                        <th>USER <span style={{ color: "red" }}>*</span></th>
                                        <th>SERIAL <span style={{ color: "red" }}>*</span> NUMBER </th>
                                        <th>WITNESS <span style={{ color: "red" }}>*</span> INITIALS</th>
                                        <th>DEGAUSS <span style={{ color: "red" }}>*</span> SUCCESS?</th>
                                        <th>PHYSICAL <span style={{ color: "red" }}>*</span> DESTRUCTION?</th>
                                        <th>COMMENTS</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className={this.state.hiddenExtraRows}>
                                        <td>
                                            {/* <input type="date" onChange={this.onChange} name="Date_Time"></input> */}
                                            <DatePicker
                                                selected={this.state.Date_Time}
                                                onChange={this.handleChange}
                                                showTimeSelect
                                                timeFormat="HH:mm"
                                                timeIntervals={15}
                                                dateFormat="MM/dd/yyyy h:mm aa"
                                            />
                                        </td>
                                        <td>
                                            <select onChange={this.onSelectChange} id="Device" disabled name="DeviceOther" value={this.state.DeviceOther}>
                                                <option value="T-1" selected>T-1</option>
                                                <option value="T-1.2">T-1.2</option>
                                                <option value="T-1.5">T-1.5</option>
                                                <option value="T-4">T-4</option>
                                                <option value="T-5">T-5</option>
                                                <option value="Other">Other</option>

                                                {/* <option value="T-1.5">T-1.5</option> */}
                                                {/* <option value="T-4">T-4</option>
                                            <option value="T-5">T-5</option> */}
                                                {/*  <option value="wand">wand</option>  */}
                                            </select>

                                            <input type="text" name="Device" id="DeviceOther" style={{ display: "none" }} onChange={this.onChange} value={this.state.Device}></input>
                                        </td>
                                        <td>
                                            {this.state.Physical_Destruction_Device === "OTHER" ?

                                                <textarea type="text" name="Description" id="Description" onChange={this.onChange} value={this.state.Description}></textarea> :

                                                <select onChange={this.onSelectChange1} id="Physical_Destruction_Device" disabled name="Physical_Destruction_Device" value={this.state.Physical_Destruction_Device}>
                                                    <option value="NONE" selected>NONE</option>
                                                    <option value="PDS-75">PDS-75</option>
                                                    <option value="PDS-100">PDS-100</option>
                                                    <option value="PDS-SSD">PDS-SSD</option>
                                                    <option value="PDS-78">PDS-78</option>
                                                    <option value="PDS-88">PDS-88</option>
                                                    <option value="PDS-104 MULTIMEDIA">PDS-104 MULTIMEDIA</option>
                                                    <option value="PDS-105 MULTIMEDIA">PDS-105 MULTIMEDIA</option>
                                                    <option value="OTHER">OTHER</option>
                                                </select>
                                            }
                                        </td>
                                        <td>

                                            <input type="email" minLength={10} required onChange={this.onChange} value={this.state.User} name="User"></input>


                                        </td>
                                        <td>
                                            <input type="text" required maxlength="45" onChange={this.onChange} value={this.state.SerialNumber} id="SerialNumber" name="SerialNumber"></input>
                                        </td>

                                        <td>
                                            <input type="text" required maxlength="45" onChange={this.onChange} value={this.state.Wireless_Initials} name="Wireless_Initials"></input>
                                        </td>
                                        <td>
                                            <input type="text" required maxlength="45" onChange={this.onChange} value={this.state.Degauss_Success} id="Degauss_Success" name="Degauss_Success"></input>
                                        </td>
                                        <td>
                                            <input type="text" onChange={this.onChange} value={this.state.Physical_Destruction} id="Physical_Destruction" name="Physical_Destruction"></input>
                                        </td>
                                        {/* <td> 
                                        <MultiSelect options={ssdHddOptions} fieldName="SSD_HDD" value={this.state.SSD_HDD} onSelect={this.onMultiSelect}></MultiSelect> 
                                    </td> */}
                                        <td>
                                            <textarea onChange={this.onChange} value={this.state.Comments} name="Comments"></textarea>
                                        </td>
                                    </tr>
                                    <tr className={this.state.hiddenExtraRows}>
                                        <td align="right" colSpan={8}>
                                            <button type="button" onClick={this.hideForm} className="btn btn-danger">CANCEL</button>
                                            <button type="button" onClick={this.saveDeviceData} className="btn btn-success">SAVE</button>
                                        </td>
                                    </tr>
                                    <tr className={this.state.hiddenExtraRows}>
                                        <td align="center" colSpan={8}>
                                            <img className='img-responsive display-initial' src={degauss}></img>
                                            {/* <div className="col-md-12 float-left">
                                            <div className="col-md-3 float-left text-right">
                                                <img className='img-responsive display-initial' src={successimg}></img> 
                                            </div>
                                            <div className="col-md-6 float-left">

                                            </div>
                                            <div className="col-md-3 float-left ">
                                                <img className='img-responsive display-initial' src={failedimg}></img> 
                                            </div>
                                        </div>  */}
                                        </td>
                                    </tr>
                                    <tr className={this.state.hiddenExtraRows}>
                                        <td align="center" colSpan={8}>
                                            <img className='img-responsive display-initial' src={physical}></img>
                                            {/* <div className="col-md-12 float-left">
                                            <div className="col-md-3 float-left text-right">
                                                <img className='img-responsive display-initial' src={yesimg}></img> 
                                            </div>
                                            <div className="col-md-6 float-left">

                                            </div>
                                            <div className="col-md-3 float-left ">
                                                <img className='img-responsive display-initial' src={noimg}></img> 
                                            </div>
                                        </div> */}
                                        </td>
                                    </tr>

                                    {this.state.userDevices.map((userDevice, Key) =>
                                        <tr key={Key}>



                                            {console.log(">>>>>>>>>>>>>>>>>>>>>>dtatataatat", userDevice.Date_Time
                                            )}
                                            <td>
                                                <DatePicker
                                                    selected={userDevice && moment(userDevice.Date_Time).toDate()}
                                                    // selected={startDate}
                                                    onChange={this.handleChange}
                                                    disabled={true}
                                                    timeFormat="HH:mm"
                                                    timeIntervals={15}
                                                    dateFormat="yyyy/MM/d"
                                                />


                                            </td>
                                            <td>
                                                {
                                                    userDevice.Device == "T-1" ?
                                                        <select disabled onChange={this.onChange} value={userDevice.Device} name="Device">
                                                            <option value="T-1">T-1</option>
                                                            {/* <option value="T-1.5">T-1.5</option> */}
                                                            {/* <option value="T-4">T-4</option>
                                            <option value="T-5">T-5</option>  */}
                                                        </select>
                                                        : <input disabled type="email" value={userDevice.Device} onChange={this.onChange} name="Device" required maxlength="45"></input>
                                                }

                                            </td>
                                            <td>
                                                <input disabled type="text" value={userDevice.Physical_Destruction_Device} onChange={this.onChange} name="Device" required maxlength="45"></input>
                                            </td>
                                            <td>
                                                <input disabled type="email" value={userDevice.User} onChange={this.onChange} name="User" ></input>
                                            </td>
                                            <td>
                                                <input disabled type="text" value={userDevice.SerialNumber} onChange={this.onChange} name="SerialNumber"></input>
                                            </td>
                                            <td>
                                                <input disabled type="text" onChange={this.onChange} value={userDevice.Wireless_Initials} name="Wireless_Initials"></input>
                                            </td>
                                            <td>
                                                <input disabled type="text" onChange={this.onChange} value={userDevice.Degauss_Success} id="Degauss_Success" name="Degauss_Success"></input>
                                            </td>
                                            <td>
                                                <input disabled type="text" onChange={this.onChange} value={userDevice.Physical_Destruction} id="Physical_Destruction" name="Physical_Destruction"></input>
                                            </td>
                                            {/* <td> 
                                        <MultiSelect options={ssdHddOptions} disabled={true} fieldName="SSD_HDD"  value={userDevice.SSD_HDD} onSelect={this.onMultiSelect}></MultiSelect> 
                                    </td> */}
                                            <td>
                                                <textarea disabled onChange={this.onChange} value={userDevice.Comments} name="Comments"></textarea>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        <div className="col-md-3  float-left">
                            <div align="center" style={{ marginBottom: "2em" }}>
                                <img className='img-responsive' src={this.state.logo} style={{ marginBottom: "0.8em" }}></img>
                                <a onClick={this.selectImage} style={{ fontSize: "1.2rem", cursor: "pointer" }}>Upload your own logo</a>
                                <input type="file" id="filelogo" onChange={this.uploadImage} style={{ display: 'none' }}></input>
                            </div>
                            <div className="row form-group">
                                <div className="col-md-12 float-left">
                                    <div className="col-md-5  float-left" style={{ paddingLeft: '0px' }} align="center">
                                        <div className="grayBlock">
                                            <h3 style={{ verticalAlign: 'middle', marginBottom: '0', fontWeight: 'bold', padding: '0.45em', fontSize: '1.2em' }}>User ID</h3>
                                        </div>
                                    </div>
                                    <div className="col-md-7  float-left pr0">
                                        <div className="grayBlock">
                                            <input type="email" onChange={this.onChange} name="UserForm" className="form-control" required></input>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row form-group">
                                <div className="col-md-12">
                                    <button type="button" onClick={this.activeUser} className="btn btn-success btn-block btn-lg">ACTIVATE USER!</button>
                                </div>
                            </div>
                            <div className="row form-group">
                                <div className="col-md-12">
                                    <div className="grayBlock">
                                        <h3 style={{ verticalAlign: 'middle', marginBottom: '0', fontWeight: 'bold', padding: '0.45em', fontSize: '1.2em' }}>Currently active user  : {this.state.ActiveUser}</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="row form-group">
                                <div className="col-md-12 float-left">
                                    <div className="col-md-5  float-left" style={{ paddingLeft: '0px' }} align="center">
                                        <div className="grayBlock">
                                            <h3 style={{ verticalAlign: 'middle', marginBottom: '0', fontWeight: 'bold', padding: '0.4em', fontSize: '1.2em' }}>Device</h3>
                                        </div>
                                    </div>
                                    <div className="col-md-7  float-left pr0">
                                        <div className="grayBlock">
                                            <select className="form-control" onChange={this.onSelectChange} value={this.state.DeviceOther} name="DeviceOther">
                                                <option value="T-1" selected>T-1</option>
                                                <option value="T-1.2">T-1.2</option>
                                                <option value="T-1.5">T-1.5</option>
                                                <option value="T-4">T-4</option>
                                                <option value="T-5">T-5</option>
                                                <option value="Other">Other</option>
                                                {/* <option value="T-1.5">T-1.5</option> */}
                                                {/* <option value="T-4">T-4</option>
                                            <option value="T-5">T-5</option> */}
                                                {/* <option value="wand">wand</option>  */}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row form-group">
                                <div className="col-md-12 float-left">
                                    <div className="col-md-5  float-left" style={{ paddingLeft: '0px' }} align="center">
                                        <div className="grayBlock">
                                            <h3 style={{ verticalAlign: 'middle', marginBottom: '0', fontWeight: 'bold', padding: '0.4em', fontSize: '1.2em' }}>Physical Destruction</h3>
                                        </div>
                                    </div>
                                    <div className="col-md-7  float-left pr0">
                                        <div className="grayBlock">
                                            <select className="form-control" onChange={this.onSelectChange1} value={this.state.Phy_Other} name="Physical_Destruction_Device">
                                                <option value="NONE" selected>NONE</option>
                                                <option value="PDS-75">PDS-75</option>
                                                <option value="PDS-100">PDS-100</option>
                                                <option value="PDS-SSD">PDS-SSD</option>
                                                <option value="PDS-78">PDS-78</option>
                                                <option value="PDS-88">PDS-88</option>
                                                <option value="PDS-104 MULTIMEDIA">PDS-104 MULTIMEDIA</option>
                                                <option value="PDS-105 MULTIMEDIA">PDS-105 MULTIMEDIA</option>
                                                <option value="OTHER">OTHER</option>

                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {this.state.saveData ? <div className="row form-group">
                                <div className="col-md-12 ">

                                    {this.state.Phy_Other == 'OTHER' && this.state.ActiveuserFlag == true ?

                                        <textarea type="text" value={this.state.Description} onChange={this.onChange} className="form-control" name="Description" rows="4"></textarea> : null
                                    }
                                </div>
                            </div> : <div className="row form-group">
                                <div className="col-md-12 ">
                                    {this.state.Physical_Destruction_Device == 'OTHER' ?

                                        <textarea type="text" value={this.state.Description} onChange={this.onChange} className="form-control" name="Description" rows="4"></textarea> : null
                                    }
                                </div>
                            </div>
                            }

                            <div className="row form-group">
                                <div className="col-md-12">
                                    <div className="grayBlock" style={{ padding: '1em' }}>
                                        <h3 style={{ verticalAlign: 'middle', marginBottom: '0', fontWeight: 'bold' }}>NUMBER OF RECORDS : {this.state.userDevices.length}</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="row form-group">
                                <div className="col-md-12">
                                    <div className="grayBlock" style={{ padding: '1em' }}>
                                        <h4 style={{ verticalAlign: 'middle', marginBottom: '0', fontWeight: 'bold' }}>TIME OF LAST EDIT :
                                            <br />
                                            {/* <Moment style={{ fontSize: 18 }} format="hh:mm:ss A | MM/DD/YYYY"> */}
                                            <span style={{ fontSize: 18 }} >
                                                {this.state.LastModifiedDate}
                                            </span>

                                            {/* </Moment> */}
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="row form-group">
                                <div className="col-md-12">
                                    <CSVLink headers={csvHeaders} filename='MyReport_Proton_Data_Security.csv' data={this.state.csvDatas} className="btn btn-success btn-block btn-lg">SAVE AS CSV</CSVLink>
                                    {/* <button type="button" className="btn btn-success btn-block btn-lg">SAVE AS CSV</button>  */}
                                </div>
                            </div>
                            <div className="row form-group">
                                <div className="col-md-12">
                                    <button type="button" onClick={this.printTableData} className="btn btn-success btn-block btn-lg">PRINT</button>
                                </div>
                            </div>
                            <div className="row form-group">
                                <div className="col-md-12">
                                    <button type="button" onClick={this.toggle} className="btn btn-success btn-block btn-lg">SEARCH</button>
                                </div>
                            </div>
                            <div className="row form-group">
                                <div className="col-md-12">
                                    <button type="button" onClick={this.fetchAllData} className="btn btn-success btn-block btn-lg">REFRESH DATA</button>
                                </div>
                            </div>
                            <div className="row form-group">
                                <div className="col-md-12">
                                    <button type="button" onClick={this.addNewRow} className="btn btn-primary btn-block btn-lg">ADD NEW ROW!</button>
                                </div>
                            </div>
                            <div className="row form-group">
                                <div className="col-md-12">
                                    <button type="button" className="btn btn-danger btn-block btn-lg" onClick={this.logout}>LOG OUT</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </form>

            </div>
        );
    }
}

export default Home