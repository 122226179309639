/*eslint-disable*/
import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import Login from './components/login';
import Home from './components/home'; 
//import usb from "usb";
//var usb = require('usb')
//var usbDetect = require('usb-detection');

class App extends Component {
 constructor(){
    super();    
    this.registerDevice();  
  }
 
  async registerDevice(){
    // var data = await navigator.usb.getDevices(); 
  } 
   
  render() {
    return ( 
      <div>
          {
            (localStorage.getItem("usersession") != null && localStorage.getItem("usersession") != undefined) ?
              <Home parentContext={this}></Home>
            :
              <Login parentContext={this}></Login> 
              
          }  
      </div> 
    );
  }
}

export default App;
