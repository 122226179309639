import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import logo from '../reglogo.png'
import StripeCheckout from 'react-stripe-checkout';
import api from '../api';

var price = 29700;

class Register extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: {},
            pkvalue: "",
            skvalue: "",
            planid: "",
            paymentDetail:null,
            stripe: null,
            loaded: false
        }; 
    }

    componentDidMount() {   
        if(this.props.match.params.id != undefined){
            api.get('users/'+this.props.match.params.id)
                .then(res => { 
                this.setState({ user: res.data }); 
            }); 
        } 

        api.get('stripe-config/')
        .then(res => { 
            if(res.data != null && res.data != undefined){
                this.setState({ pkvalue: res.data.test_pub_key }); 
                this.setState({ skvalue: res.data.test_secret_key }); 
                this.setState({ planid: res.data.test_plan_id }); 

                if (window.Stripe) {
                    this.setState({stripe: window.Stripe(res.data.test_pub_key)});
                } else {
                    document.querySelector('#stripe-js').addEventListener('load', () => {
                        // Create Stripe instance once Stripe.js loads
                        this.setState({stripe: window.Stripe(res.data.test_pub_key)});
                    });
                }
            }
        }); 
    } 

    onToken = (token, addresses) => {  
        var userData = this.state.user;
        userData.address_line1= token.card.address_line1;
        userData.city=token.card.address_city;
        userData.country=token.card.address_country;
        userData.state= token.card.address_state;
        userData.pincode= token.card.address_zip;
        userData.cardNumber= token.card.last4;
        this.setState({ user: userData });  
       
        this.setState({ loaded: true}); 
        api.get('payment-process/'+token.id + '/' + this.state.skvalue + '/' + price + '/' + this.state.user.email + "/" + this.state.planid)
            .then(res => {   
                var userData = this.state.user;
                userData.strp_customer_id= res.data.customer;  
                userData.strp_charge_id= res.data.id; 
                api.put('users/'+this.props.match.params.id, userData)
                .then((result) => {
                    this.setState({ loaded: false}); 
                    localStorage.setItem("usersession",this.props.match.params.id);
                    this.props.history.push("/"); 
                });
        }); 
    };  

    render() {
        return (
          <div className="App">
            <div className="appBody"> 
            <img className='img-responsive' src={logo} style={{marginBottom:'1em'}}></img> 
            <h3 className="pageTitle">PROTON DATA DEFENDER RECORD GENERATION SERVICE</h3>
            {
                    this.state.loaded ?  
                    <div id="cover-spin"></div>
                    : <div></div>
            }
            <div className="panel" align="center"  style={{width:'300px'}}>
                <h3 className="h3 mg1">ONE SIZE FITS ALL</h3>
                <div className="price-detail">
                    <span className="price">$297</span>
                    <span className="priceper">PER COMPANY/ANNUALY</span>
                </div>
                <div className="description">
                    ONE SHARED LOGIN THEN AS <br />
                    MANY USERS AS YOU WANT <br />
                    TO HAVE TO KEEP YOUR <br />RECORDS
                </div>
                <br></br>
                <br></br>
               {/* {
                   this.state.paymentDetail != null ? 
                   <div className="description">
                        <div> <b>Charge Id :</b> {this.state.paymentDetail.id}</div>
                        <div> <b>Transcation Id :</b> {this.state.paymentDetail.balance_transaction}</div>
                        <div> <b>Name :</b> {this.state.paymentDetail.source.name}</div>
                        <div> <b>Address line 1:</b> {this.state.paymentDetail.source.address_line1}</div>
                        <div> <b>City:</b> {this.state.paymentDetail.source.address_city}</div>
                        <div> <b>Pincode:</b> {this.state.paymentDetail.source.address_zip}</div> 
                        <div> <b>Country :</b> {this.state.paymentDetail.source.address_country}</div>
                        <div> <b>Card last 4 digits :</b> {this.state.paymentDetail.source.last4}</div>
                        <div> <b>Status :</b> {this.state.paymentDetail.status}</div>
                   </div> : ""
               } */}

                <hr></hr>
                {
                    (this.state.pkvalue != "") ?    
                        <StripeCheckout
                            amount={price}
                            billingAddress   
                            email={this.state.user.email}
                            image={logo}
                            locale="auto"
                            name="Proton Data Security"
                            stripeKey={this.state.pkvalue}
                            token={this.onToken}
                            zipCode
                            panelLabel="Pay {{amount}}"
                        />
                    :
                        <span></span>
                }
              
                {/* <Link  className="btn btn-success btn-block btn-lg" style={{color:'white'}} to={`/signup`}>SIGN UP</Link>  */}
            </div>
            </div>
          </div>
        );
    }
}

export default Register;

